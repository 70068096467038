.registry-page {
    position: relative;
    background: url('/public/images/mon.jpg') no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px;
  }
  /* Fix stretched background on mobile */
@media (max-width: 768px) {
    .registry-page {
        background: url('/public/images/mon.jpg') repeat center center fixed;
        background-size: contain; /* Prevents extreme stretching */
        background-attachment: scroll; /* Stops fixed background issues */
    }
}
/* General Page Styling */
.registry-container {
    max-width: 1200px;
    margin: auto;
    padding: 40px 35px;
    text-align: center;
    font-family: "Playfair Display", serif !important;
    background: rgba(253, 253, 253, 0.85); /* Adjust the last value (0.85) for transparency */
    border: 2px solid #bfa58a;
    box-shadow: 0 8px 24px rgba(43, 35, 35, 0.2);

}

/* Title Styling */
.registry-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #2c2c2c;
    margin-bottom: 40px;
    letter-spacing: 1px;
    border-bottom: 3px solid #bfa58a;
    display: inline-block;
    padding-bottom: 10px;
}

/* Grid Layout */
.registry-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 40px;
    justify-content: center;
}

/* Individual Item Card */
.registry-item {
    background: white;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    padding: 25px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.registry-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15);
}

/* Image Gallery */
.image-gallery {
    position: relative;
    text-align: center;
}

.registry-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    transition: opacity 0.4s ease-in-out;
}

/* Thumbnail Container */
.thumbnail-container {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 10px;
}

.thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.thumbnail:hover {
    transform: scale(1.1);
}

.thumbnail.active {
    border: 2px solid #007bff;
}

/* Item Title - Clean Professional Look */
.registry-item-title {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
    text-align: center;
    text-transform: none;
    margin-top: 15px;
}

/* Price */
.registry-item-price {
    font-size: 1.2rem;
    color: #555;
    font-weight: 500;
    margin: 10px 0;
}

/* Customization Instructions */
.customization-note {
    font-size: 1rem;
    color: #444;
    background: #fff3e0;
    padding: 12px;
    border-left: 5px solid #ff9500;
    border-radius: 6px;
    margin-top: 12px;
    font-weight: 500;
}

/* Buy Button */
.buy-button {
    display: inline-block;
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    background: #007bff;
    border-radius: 6px;
    text-decoration: none;
    transition: background 0.3s ease-in-out;
    margin-top: 15px;
    border: none;
}

.buy-button:hover {
    background: #0056b3;
}

/* Mark as Purchased Button */
.mark-purchased {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #555;
    background: #e6e6e6;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.mark-purchased:hover {
    background: #ccc;
}

/* --- MODAL OVERLAY --- */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Softer overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
}

/* Modal Box */
.form-modal {
    background: white;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 90%;
    max-width: 450px;
    font-family: 'Helvetica Neue', sans-serif;
}
/* --- FRONTGATE MODAL --- */
.frontgate-modal-content {
    background: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 90%;
    max-width: 450px;
}

/* Frontgate Modal Title */
.frontgate-modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2c2c2c;
    font-family: 'Helvetica Neue', sans-serif;
    margin-bottom: 15px;
}

/* Frontgate Modal Text */
.frontgate-modal-text {
    font-size: 1rem;
    color: #444;
    line-height: 1.5;
    padding: 0 15px;
}

/* Frontgate Modal Button */
.frontgate-button {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 15px auto 0;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background: #007bff;
    border-radius: 6px;
    border: none;
    transition: background 0.3s ease-in-out;
    text-decoration: none;
    text-align: center;
    
}

.frontgate-button:hover {
    background: #0056b3;
}



/* frontgate Cancel Button */
.frontgate-cancel-button {
    background: #e6e6e6;
    color: #555;
    width: 100%;
    max-width: 300px;
    margin-top: 10px;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 6px;
    border: none;
    cursor: pointer;
} 

.cancel-button:hover {
    background: #ccc;
}


/* Title */
.modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    font-family: 'Helvetica Neue', sans-serif;

}

/* Modal Text */
.modal-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    padding: 10px 0;
}

/* Form Styling */
.purchase-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;
}

/* Form Labels */
.form-label {
    font-size: 1rem;
    font-weight: 500;
    color: #444;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

/* Form Inputs */
.purchase-form input {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Buttons Container */
.modal-buttons {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 15px;
}

/* Confirm & Cancel Buttons */
.confirm-button,
.cancel-button {
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    transition: background 0.3s ease-in-out;
    width: 45%;
    text-align: center;
}

/* Confirm Button */
.confirm-button {
    background: #007bff;
    color: white;
}

.confirm-button:hover {
    background: #0056b3;
}

/* Cancel Button */
.cancel-button {
    background: #e6e6e6;
    color: #555;
}

.cancel-button:hover {
    background: #ccc;
}

/* CTA Button for External Purchase */
.proceed-button {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background: #007bff;
    border-radius: 6px;
    border: none;
    transition: background 0.3s ease-in-out;
    text-decoration: none;
    text-align: center;
    margin-top: 10px;
}

.proceed-button:hover {
    background: #0056b3;
}

/* --- REGISTRY HUBS SECTION --- */
.registry-hubs-container {
    margin-top: 50px;
    text-align: center;
}

.registry-hubs-title {
    font-size: 2rem;
    font-weight: bold;
    color: #2c2c2c;
    margin-bottom: 45px;
}

.registry-hubs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.registry-hub {
    background: white;
    border: 2px solid #ddd;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
    text-align: center;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.registry-hub:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 14px rgba(0,0,0,0.15);
}

.registry-hub img {
    max-width: 150px;
    height: auto;
    margin-bottom: 10px;
}

/* --- Responsive Adjustments --- */
@media (max-width: 768px) {
    .registry-grid {
        grid-template-columns: 1fr;
    }

    .thumbnail {
        width: 40px;
        height: 40px;
    }

    .registry-hubs-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    .form-modal {
        max-width: 90%;
    }

    .confirm-button,
    .cancel-button {
        width: 100%;
    }
}
