/* Ensures the entire section is scrollable if needed */
.events-container {
    width: 100%;
    overflow-x: auto; /* Enables horizontal scrolling */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

/* Wrapper for the image to prevent extra white space */
.scroll-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%; /* Ensures it takes the full screen width */
    overflow-x: auto; /* Enables horizontal scrolling */
}

/* The actual PNG image */
.scrollable-image {
    width: 100%; /* Makes sure it fits the full width */
    max-width: 2000px; /* Adjust if needed (for high-res images) */
    height: auto; /* Keeps aspect ratio */
    display: block;
}

/* Responsive Tweaks */
@media (max-width: 768px) {
    .scrollable-image {
        width: 100%; /* Ensures it scales down on smaller screens */
    }
}
